<template>            
  <RightSideBar @close="$emit('close')" button-class="bg-dynamicBackBtn text-white" :submit="'Save'" :cancel="'Go Back'" :loading="isLoading" @submit="submit" :disabled-button="false">
    <template v-slot:title>
      <div class="text-darkPurple flex gap-2 justify-center items-center">
          <div class="cursor-pointer" @click="closeSidebar()"><icon icon-name="back_arrow" size="s" class="w-8 h-8 rounded text-romanSilver"/></div> 
          <p class="font-bold">Manage Allocation</p>    
      </div>
    </template>

    <card class="w-full h-16 pl-2 py-4 text-darkPurple flex justify-start items-center gap-2">
        <CircularProgressBar :percentage="totalStorageUseage"/>   
        <div>
            <p class="text-jet text-sm font-semibold">Total Storage</p>
            <p class="font-black text-romanSilver size">{{ storageUseage }} of {{ totalGB }}</p>
        </div>
    </card>

    <div class="w-full h-auto flex flex-col justify-between gap-4 mt-5">
        <DocsAllocations 
            type="Organisation Documents"
            typeIcon="blue-building"
            :percentage="orgDocTotalUsage"
            :documents="orgDocuments"
        />

        <DocsAllocations 
            type="Employee Documents"
            typeIcon="hero-icon"
            :percentage="empDocTotalUseage"
            :documents="empDocuments"
        />
    </div>

    <div class="mt-7 w-full flex justify-between items-center">
        <div class="flex justify-start items-start gap-4">
            <icon icon-name="hero-icon" size="xs"/>
            <p class="font-semibold text-xs uppercase">Employee</p>
        </div>

        <div class="w-full" v-if="!showPrivilegeError" style="width: 224px;">
            <div class="w-full relative">
                <v-select
                    :clearable="false"
                    :placeholder="`${users.length >= 1 ? 'Search employees' : 'Search here'}`"
                    label="fname"
                    class="style-select"
                    style="height: 40px; background: #F7F7FF !important; border: none !important; outline: none !important"
                    :reduce="option => option"
                    v-model="search"
                    multiple
                    @search="setAllOrgUsers"
                    @input="handleSelect"
                    :options="allOrgUsers"
                    :filterable="false"
                >
                <template #search="{ attributes, events }">
                    <div class="input-with-icon-container">
                        <img
                        src="@/assets/images/magnifying-glass.png"
                        style="width: 20px; height: 20px; margin-left: 10px; margin-right: 5px;"
                        />
                        <input
                            v-bind="attributes"
                            v-on="events"
                            :placeholder="attributes.placeholder" />
                    </div>
                </template>
                <template
                    #option="{fname, lname, orgFunction, userDesignation, photo }"
                >
                <div class="flex">
                    <span class="mt-2 ">
                    <img
                        class=""
                        :src="photo"
                        v-if="photo"
                        alt="profile pic"
                        style="height: 25px; width: 25px; border-radius: 5px;"
                    />
                    <div
                        style="height: 25px; width: 25px; border-radius: 5px;"
                        class="text-blueCrayola text-xs border text-center font-semibold pt-1"
                        v-else
                    >
                        {{ $getInitials(`${fname} ${lname}`) }}
                    </div>
                    </span>
                    <div class="ml-2 hover:text-white">
                    <div class="font-bold mt-1 text-sm cursor-pointer">
                        {{ fname }} {{ lname }}
                    </div>
                    <div class=" " style="font-size: 10px">
                        <span class="uppercase">{{
                        orgFunction ? orgFunction.name : ""
                        }}</span>
                        <span class="ml-2">{{
                        userDesignation ? `${userDesignation.name}` : ""
                        }}</span>
                    </div>
                    </div>
                </div>
                </template>
                <template #selected-option="{fname, lname}">
                    {{ fname }} {{ lname }}
                </template>
                <template #open-indicator="{ attributes }">
                    <span v-bind="attributes" class="bg-ghostWhite h-full m-auto flex justify-center items-center">
                        <icon icon-name="caret-down" size="xsm" />
                    </span>
                </template>
                <template #list-footer>
                    <li style="text-align: center; font-size: 12px">
                        Type name of employee to search more
                    </li>
                </template>
            </v-select>
            </div>
        </div>
        <div v-else class="w-full mt-4">
            <p class="flex font-bold text-desire text-sm justify-center">
                You don't have employees privilege
            </p>
        </div>
    </div>

    <div class="w-full flex justify-between items-center text-sm font-semibold my-5">
        <div class="width h-11 px-3 bg-guyabano flex justify-between items-center">
            <div class="w-2/3 flex justify-start items-center gap-1">
                <icon icon-name="home-storage" size="xs" class="icon-width"/>
                <p class="flex flex-col justify-start items-start">
                    <span class="text-xs">Total Storage: </span>
                    <span class="size text-jet font-normal">{{ empDocuments.length ? empDocuments.length : 0 }} employees</span>
                </p>
            </div>
            <div class="w-20 h-9 flex justify-between items-center pl-2 relative bg-white rounded-md border border-solid border-romanSilver">
                <p>{{ totalStorageGB }}</p>
                <input type="text" value="GB" readonly
                    class="w-8 h-5 absolute top7 left-5 text-sm text-darkPurple bg-transparent font-normal outline-none border-none"
                />
                <div class="h-full absolute right-2 top flex flex-col">
                    <div @click="increaseGB('totalStorageGB', totalStorageGB, 5)" class="cursor-pointer">
                        <icon icon-name="caret-down" size="xsm" class="rotate"/>
                    </div>
                    <div @click="decreaseGB('totalStorageGB', totalStorageGB, 0)" class="cursor-pointer">
                        <icon icon-name="caret-down" size="xsm" />
                    </div>
                </div>
            </div>
        </div>
        <div class="h-6 border border-solid border-borderGrey"/>
        <div class="width h-11 pl-3 bg-guyabano flex justify-start items-center gap-1 ">
            <CircularProgressBar :percentage="totalStorageUseage" width="24" height="24"/>
            <p>{{ totalStorageUseage.toFixed(2)}}% of Total Space Allocated</p>
        </div>
    </div>

    <div class="w-full h-6 mb-4 uppercase flex justify-between items-center text-xs font-black text-romanSilver">
        <p>{{ users.length >= 1 ? "Storage Allocation" : "Total Storage Allocation" }}</p>
        <p>Set max storage</p>
    </div>

    <div v-if="users.length >= 1" class="w-full h-auto">
        <div
            class="w-full h-12 mb-3 flex justify-between items-center"
            v-for="(user, index) in users"
            :key="index"
        >
            <div class="flex justify-start items-center">
                <div class="">
                    <img
                        class=""
                        :src="user.photo"
                        v-if="user.photo"
                        alt="profile pic"
                        style="height: 30px; width: 40px; border-radius: 5px;"
                    />
                    <div
                        style="height: 30px; width: 40px; border-radius: 5px;"
                        class="text-blueCrayola text-xs border text-center font-semibold pt-1"
                        v-else
                    >
                        {{ $getInitials(`${user.name}`) }}
                    </div>
                </div>
                <div class="ml-2">
                    <div class="font-bold mt-1 text-sm text-darkPurple">
                        {{ user.name }}
                    </div>
                    <div style="font-size: 10px">
                        <span class="uppercase text-romanSilver">
                            {{ user.orgFunction ? user.orgFunction : "" }}
                        </span>
                    </div>
                </div>
            </div>
       
            <div class="w-40 h-10  flex justify-start items-center gap-2 pl-3 relative text-darkPurple rounded-md border border-solid border-romanSilver">
                <p>{{ user.allocatedSpace }}</p>
                <input type="text" placeholder="GB" readonly
                    class="w-12 h-5 text-base text-darkPurple bg-transparent font-semibold outline-none border-none"
                />
                <div class="h-full absolute right-3 flex flex-col">
                    <div @click="increaseMB(user.userId)" class="cursor-pointer">
                        <icon icon-name="caret-down" size="xsm"  class="rotate"/>
                    </div>
                        <div @click="decreaseMB(user.userId)" class="cursor-pointer">
                        <icon icon-name="caret-down" size="xsm" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-else class="w-full h-12 flex justify-between items-center">
        <div class="flex flex-row items-center">
            <div>
                <icon icon-name="icon-folder" size="s" class="w-8 h-8 rounded text-romanSilver"/>
            </div>
    
            <p class="ml-2 uppercase text-xs font-semibold text-jet">
                Per employee storage allocation
            </p>
        </div>

        <div class="w-40 h-10 flex justify-start items-center gap-2 pl-3 relative text-darkPurple rounded-md border border-solid border-romanSilver">
            <p>{{ perEmpStorage }}</p>
            <input type="text" placeholder="GB" readonly
                class="w-12 h-5 text-base text-darkPurple bg-transparent font-semibold outline-none border-none"
            />
            <div class="h-full absolute right-3 flex flex-col">
                <div @click="increaseGB('perEmpStorage', perEmpStorage, 12)" class="cursor-pointer">
                    <icon icon-name="caret-down" size="xsm"  class="rotate"/>
                </div>
                    <div @click="decreaseGB('perEmpStorage', perEmpStorage, 0)" class="cursor-pointer">
                    <icon icon-name="caret-down" size="xsm" />
                </div>
            </div>
        </div>
    </div>
  </RightSideBar>
</template>

<script>
import "vue-select/dist/vue-select.css";
import { mapState } from "vuex";
import RightSideBar from "@/components/RightSideBar";
import DocsAllocations from './DocsAllocations';
import SearchInput from "@scelloo/cloudenly-ui/src/components/search-input";
import CircularProgressBar from "../CircularProgressBar";
import Card from "@/components/Card"

export default {
    name: "ManageAllocation",
    components: {
        RightSideBar,
        DocsAllocations,
        SearchInput,
        CircularProgressBar,
        Card,
        vSelect: () => import("vue-select"),
    },
    props: {
        orgDocuments: {
            type: Array,
            default: () => []
        },
        empDocuments: {
            type: Array,
            default: () => []
        },
        totalStorageUseage: Number,
        orgDocTotalUsage: Number,
        empDocTotalUseage: Number,
        totalGB: String || Number,
        storageUseage: String || Number
    },
    data() {
        return {  
            isLoading: false,
            perEmpStorage: 0,
            totalStorageGB: 5,
            search: "",
            searchPlaceHolder: "Search here",
            userIds: [],
            users: [],
            allOrgUsers: [],
            showPrivilegeError: false,
        };
  },
  computed: {
    ...mapState({
      loading: (state) => state.employee.loading,
      employees: (state) => state.employee.employees
    })
  },
  methods: {
        closeSidebar() {
            this.$emit("close");
        },

        async getEmployeeDetails() {
            try {
                await this.$handlePrivilege(
                    "employeeDirectory",
                    "viewAllEmployeesRegister"
                );
                this.$store.dispatch(
                    "employee/getEmployeesWithQueries",
                    "&page=1&perPage=50"
                );
                this.showPrivilegeError = false;
            } catch (error) {
                this.showPrivilegeError = true;
            }
        },

        async setAllOrgUsers(search, loading) {
            loading(true);
            try {
                const attributes = "id,fname,lname,photo,userId";
                const { data } = await this.$_getAllEmployeesWithQueries(
                `search=${search}&perPage=1000&payrollStatus=active&attributes=${attributes}`
                );
                this.allOrgUsers = data.data.employees;
                loading(false);
            } catch (err) {
                throw new Error(err);
            }
        },
        handleSelect(selected) {
            this.userIds.push(selected[0].userId);
            this.users.push({
                userId: selected[0].userId, 
                name: `${selected[0].fname} ${selected[0].lname}`,
                photo: selected[0].photo,
                orgFunction: selected[0].orgFunction?.name,
                allocatedSpace: 0,
            });
            this.search = "";
        },

        increaseMB(userId){
            this.users.map(user => {
                if(user.userId === userId){
                    if(user.allocatedSpace === 12){
                            return user.allocatedSpace
                        }
                    return user.allocatedSpace++;
                }else{
                    return user.allocatedSpace
                }
            })
        },
        decreaseMB(userId){
            this.users.map(user => {
                if(user.userId === userId){
                    if(user.allocatedSpace === 0){
                        return user.allocatedSpace
                    }
                    return user.allocatedSpace--;
                }else{
                    return user.allocatedSpace
                }
            })
        },

        increaseGB(varName, storage, value){
            if(storage === value){
                return this[varName]
            }
            return this[varName]++;
        },
        decreaseGB(varName, storage, value){
            if(storage === value){
                return this[varName]
            }
            return this[varName]--
        },

        async submit(){
            this.isLoading = true;
            const orgId = this.$orgId;
            const users = this.users.map(user => ({
                userId: user.userId,
                allocatedSpace: user.allocatedSpace  * 1024 * 1024
            }));
            const orgDocs = this.orgDocuments.map(doc => ({
                folderId: doc.folderId,
                allocatedSpace: doc.allocatedSpace

            }));
            const empDocs = this.empDocuments.map(doc => ({
                folderId: doc.folderId,
                allocatedSpace: doc.allocatedSpace
            }));
            const payload = {
                folders: [
                    ...orgDocs,
                    ...empDocs
                ],
                users: this.users.length >= 1 ? users : null,
                allUsers: this.users.length >= 1 ? false : true,
                allUsersAllocatedSpace: this.users.length >= 1 ? null : this.perEmpStorage * 1024 * 1024
            }
            try {
                const { data } = await this.$_manageStorageAllocation(orgId, payload);
                this.isLoading = false;
                this.closeSidebar();
                return this.$toasted.success(`${data.message}.`, {duration: 6000});
            } catch (err) {
                this.isLoading = false;
                return this.$toasted.error(`${err.message}`, {duration: 6000});
            } 
        }
    },
    async mounted() {
        await this.getEmployeeDetails() 
  },
};
</script>

<style scoped>
.width{
  width: 47%;
}

.search{
    height: 20px !important;
    border-bottom: 1px solid #878E99 !important;
    margin-bottom: 28px !important;
}

.rotate{
    transform: rotate(-180deg) !important;
}

.size{
    font-size: 10px;
}

.top{
  top: -4px !important;
}

.top7{
  top: 7px !important;
}

.icon-width{
  width: 22px !important;
  height: 22px !important;
}

.input-with-icon-container {
  width: 100%;
  height: 40px;
  margin: auto;
  display: flex;
  justify-items: start;
  align-items: center;
  background-color: #F7F7FF; 
  border-radius: 6px;
  border: none !important;
  outline: none !important;
}

.input-with-icon-container input{
  width: 100%;
  height: 100%;
  background-color: #F7F7FF !important; 
  border: none !important;
  outline: none !important;
}
</style>
