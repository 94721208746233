<template>            
  <div class="w-full text-jet">
    <div class="flex justify-start items-start gap-4 mt-5">
        <icon :icon-name="typeIcon" size="xs"/>
        <p class="font-semibold text-xs uppercase">{{type}}</p>
    </div>
    <div class="line"></div>

    <div class="w-full flex justify-between items-center text-sm font-semibold mb-5">
      <div class="width h-11 px-3 bg-guyabano flex justify-between items-center">
          <div class="w-2/3 flex justify-start items-center gap-1">
            <icon icon-name="home-storage" size="xs" class="icon-width"/>
            <p>Total Storage: </p>
          </div>
          <div class="w-20 h-9 flex justify-between items-center pl-2 relative bg-white rounded-md border border-solid border-romanSilver">
            <p>{{ totalStorageGB }}</p>
            <input type="text" value="GB" readonly
                class="w-8 h-5 absolute top7 left-5 text-sm text-darkPurple bg-transparent font-normal outline-none border-none"
            />
            <div class="h-full absolute right-2 top flex flex-col">
              <div @click="increaseTotalStorageGB" class="cursor-pointer">
                  <icon icon-name="caret-down" size="xsm" class="rotate"/>
              </div>
                  <div @click="decreaseTotalStorageGB" class="cursor-pointer">
                  <icon icon-name="caret-down" size="xsm" />
              </div>
            </div>
          </div>
      </div>
      <div class="h-6 border border-solid border-borderGrey"/>
      <div class="width h-11 pl-3 bg-guyabano flex justify-start items-center gap-1 ">
          <CircularProgressBar :percentage="percentage" width="24" height="24"/>
          <p>{{ percentage.toFixed(2)}}% of Total Space Allocated</p>
      </div>
    </div>

    <sTable
      :headers="foldersHeader"
      :items="documents"
      style="width: 100%"
      class="w-full"
      aria-label="storage table"
      :has-number="false"
      :loading="false"
      :has-checkbox="false"
      v-if="documents.length > 0"
    >
      <template v-slot:item="{ item }">
        <div v-if="item.name" class="flex flex-row items-center">
          <div>
            <icon icon-name="icon-folder" size="s" class="icon-width text-romanSilver"/>
          </div>
        
          <p class="ml-1 flex flex-col justify-between">
            <span class="font-semibold text-xs text-jet">
              {{ item.data.name }}
            </span>
            <span class="font-normal text-blueYonder size11">
              {{ item.data?.numberOfFiles ?? 0  }} Files
            </span>
          </p>
        </div>

        <div v-else-if="item.folderSize">
          <div class="w-full h5 bg-cultured rounded-md">
              <div class="h-full bg-blueCrayola rounded-md"
                  :style="`width: ${item.data.folderSize / 10485760 * 100 > 100 || item.data.folderSize / 10485760 * 100}%`"
              >
              </div>
          </div>
          <p class="mtop text-jet size11 font-normal">
            Using {{ kbToSize(item.data.folderSize) }} of {{ item.data.allocatedSpace }} GB
          </p>
        </div>

        <div v-else-if="item.allocatedSpace" 
          class="w-40 h-10 flex justify-start items-center gap-2 pl-3 relative text-darkPurple rounded-md border border-solid border-romanSilver"
        >
          <p>{{item.data.allocatedSpace}}</p>
          <input type="text" placeholder="GB" readonly
            class="w-12 h-5 text-darkPurple bg-transparent font-semibold outline-none border-none"
          />

          <div @click="increaseGB(item.data.folderId)" class="cursor-pointer absolute right-3 top-0">
            <icon icon-name="caret-down" size="xsm"  class="rotate"/>
          </div>
          <div @click="decreaseGB(item.data.folderId)" class="cursor-pointer absolute right-3 bottom-0">
            <icon icon-name="caret-down" size="xsm" />
          </div>
        </div>
      </template>
    </sTable>

    <div v-if="documents.length === 0">
      <div class="w-full h-9 flex justify-between items-center"> 
        <div v-for="(header, index) in foldersHeader" :key="index" >
          <p class="text-romanSilver text-xs font-black">{{ header.title }}</p>
        </div>
      </div>
      <EmptyState />
    </div>
  </div>
</template>

<script>
import STable from "@scelloo/cloudenly-ui/src/components/table";
import CircularProgressBar from "../CircularProgressBar"
import EmptyState from "../EmptyState";

export default {
  name: "DocAllocations",
  components: {
    STable,
    CircularProgressBar,
    EmptyState
  },
  props: {
    type: String,
    typeIcon: String,
    documents: {
        type: Array,
        default: () => []
    },
    percentage: Number,
  },
  data() {
    return {  
      totalStorageGB: 5,
      foldersHeader: [
        { title: "Folder name", value: "name" },
        { title: "Current Utilization", value: "folderSize"},
        { title: "Set max storage limit", value: "allocatedSpace"},
      ]
    };
  },
  methods: {
    increaseGB(id){
      this.documents.map(doc => {
        if(doc.folderId === id){
          if(doc.allocatedSpace === 12){
                return doc.allocatedSpace
            }
            return doc.allocatedSpace++;
         }else{
           return doc.allocatedSpace
         }
      })
    },
    decreaseGB(id){
      this.documents.map(doc => {
        if(doc.folderId === id){
          if(doc.allocatedSpace === 0){
                return doc.allocatedSpace
            }
            return doc.allocatedSpace--;
         }else{
           return doc.allocatedSpace
         }
      })
    },
    
    increaseTotalStorageGB(){
      if(this.totalStorageGB === 5){
          return this.totalStorageGB
      }
      return  this.totalStorageGB++;
    },
    decreaseTotalStorageGB(){
      if(this.totalStorageGB === 0){
          return this.totalStorageGB
      }
      return  this.totalStorageGB--
    },             
    kbToSize(kb) {
      const sizes = ['KB', 'MB', 'GB', 'TB', 'PB'];
      if (kb === 0) return '0 KB';
      if (kb < 1) return `${(kb * 1024).toFixed(1)} KB`; // Converted small KB to Bytes
          const i = parseInt(Math.floor(Math.log(kb) / Math.log(1024)), 10);
          const sizeIndex = Math.min(i, sizes.length - 1);
      if (sizeIndex === 0) return `${kb.toFixed(1)} ${sizes[sizeIndex]}`;
      return `${(kb / (1024 ** sizeIndex)).toFixed(1)} ${sizes[sizeIndex]}`;
    }
  }
};
</script>

<style scoped>
.line{
  border: 1px solid #C6C6C6;
  width: 100%;
  height: 0;
  margin: 20px auto;
  border-radius: 10px;
  padding: 0;
}

.width{
  width: 47%;
}

.h5{
    height: 5px;
}

.mtop{
    margin-top: 2px;
}
.top{
  top: -4px !important;
}

.top7{
  top: 7px !important;
}
.rotate{
    transform: rotate(-180deg) !important;
}

.icon-width{
  width: 22px !important;
  height: 22px !important;
}

.rotate{
    transform: rotate(-180deg) !important;
}

.size{
  font-size: 10px !important;
}

.size11{
  font-size: 10px !important;
}
</style>
