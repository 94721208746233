<template>
  <div class="storage-container">
    <svg :width="`${width}`" :height="`${height}`" viewBox="0 0 36 36" class="circular-chart"> 
      <path
        class="circle-bg"
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <path
        class="circle"
        :stroke-dasharray="`${percentage}, 100`"
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
    </svg>
  </div>
</template>

  <script>
  export default {
    name: "CircularProgressBar",
    props: {
      percentage: Number,
      width: {
        type: String,
        default: "36px"
      },
      height: {
        type: String,
        default: "36px"
      },
    },
    computed: {},
    mounted(){
      console.log(this.percentage)
    }
  };
</script>

<style scoped>
.circular-chart {
  display: block;
  max-width: 100px;
  margin: 10px auto;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 4;
}

.circle {
  fill: none;
  stroke: #2176FF;
  stroke-width: 5;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

/* @keyframes progress {
  from {
    stroke-dasharray: 0 100;
  }
  to {
    stroke-dasharray: 70 100;
  }
} */
</style>