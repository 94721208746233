<template>
    <div class="w-full flex flex-col justify-center items-center ">
        <div :style="`${width};${height}`">
            <icon icon-name="empty-box" size="xs" class="wfull"/>
        </div>
        <p>There are no folders created yet.</p>
    </div>
</template>
<script>
export default {
    name: "EmptyState",
    props: {
        width: {
            type: String,
            default: "width:180px"
        },
        height: {
            type: String,
            default: "height:117px"
        },
    },
};
</script>
<style scoped>
.wfull{
    width: 100% !important;
    height: 100% !important;
}
</style>
